.works-container {
    display: flex;
    flex-wrap: wrap;
    /* overflow-x: scroll; */
}

.ourwork-card {
    height: 350px;
    /* max-width: 280px; */
    width: min(280px, 70vw) !important;
    /* min-width: 180px; */
    padding: 2vw 2.2rem;
    margin: 30px 20px;
    /* background-color: #75c7fb; */
    background-image: linear-gradient(to bottom right, var(--color-primary), var(--color-primary-offset));
    border-radius: 15px;
    /* border: 2px solid #75c7fb; */
    /* box-shadow: 0 0 10px grey; */
    box-shadow: 0px 10px 10px var(--color-primary);
    transition: transform var(--fast), box-shadow var(--fast);
}

.ourwork-card:hover {
    transform: scale(1.03);
    box-shadow: 0px 5px 15px var(--color-primary);
}


.slick-arrow {
    z-index: 2000;
}

.slick-slide>div {
    text-align: center;
}

.slick-next,
.slick-prev {
    width: 50px !important;
    height: 50px !important;
}

.slick-prev:before,
.slick-next:before {
    color: rgb(0, 153, 255) !important;
    font-size: 40px !important;
}


.ourwork-card>h3 {
    text-align: center;
    color: white;
    padding-bottom: 5px;
    border-bottom: 1px solid black;
}

/* .ourwork-card {
    height: 350px;
    min-width: 300px;
    padding: 20px;
    margin: 30px 20px;
    border-radius: 15px;
    border: 2px solid #75c7fb;
}

*/

.ourwork-card>p {
    text-align: center;
    margin-top: 15px;

}