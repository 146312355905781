footer {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    /* flex-direction: column; */
    /* display: grid;
    grid-template-columns: 3fr 4fr 2fr; */
    /* min-height: 325px; */
    background-color: var(--color-light);
    /* background-image: url(../images/footer-background.jpg); */
    background-size: cover;
    padding-block: 2vh;
}

footer::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    /* background-color: rgba(100, 0, 0, 0.33); */
    /* background-color: var(--color-primary); */
    left: 0;
    top: 0;
}

.footer-brand-container {
    width: 50vw;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    column-gap: 20px;
    border-right: 2px solid var(--color-primary);
}

.footer-brand-img {
    width: 100px;
}

.footer-brand-container>h2 {
    color: var(--color-primary);
}

.company-details {
    /* display: grid;
    align-self: start; */
    padding: 8px 25px;
    z-index: 5;
}

.contacts {
    /* background-color: blue; */
    flex: 3;
}

.company-details {
    /* background-color: red; */
    flex: 4;
}


footer h3 {
    color: var(--color-primary);
    margin-bottom: 20px;
    border-bottom: 1px solid var(--color-primary);
}



.detail {
    /* font-weight: bold; */
    font-size: 14px;
    color: var(--color-neutral);
    margin-bottom: 30px;
}

.detail span {
    margin-top: 5px;
    font-weight: normal;
    /* color: lightcoral; */
    color: var(--color-neutral);
}



@media screen and (max-width:570px) {
    .follow-us-icon-wrapper {
        flex-direction: row;
        justify-content: space-evenly;
    }
}

@media screen and (max-width: 400px) {
    .footer-brand-container {
        width: 100%;
        border: none;
    }

    footer {
        text-align: center;
    }
}