:root {
  --nav-height: 5.5rem;
  --color-dark-text: black;
  --color-light-text: white;
  --fast: 0.4s;
  --medium: 1.5s;
  --slow: 2.5s;
}

body {
  margin: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


* {
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
  line-height: 1.3;

  /* color: var(--color-text); */
  /* transition: color var(--fast), background-color var(--fast);
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -o-user-select: none; */
}


html {
  font-size: 62.5%;
  scroll-behavior: smooth;
}

html,
body {
  position: relative;
  scroll-behavior: smooth;
}

h1 {
  /* font-size: 6.5rem; */
  font-size: clamp(3rem, 12vw, 6.5rem);
}

h2 {
  /* font-size: 3.4rem; */
  font-size: clamp(1rem, 6vw, 3.4rem);
}

h3 {
  font-size: 2.5rem;
}

h4 {
  /* font-size: 2rem; */
  font-size: clamp(0.75rem, 4vw, 2rem);
}

h5 {
  font-size: 1.5rem;
}

h6 {
  font-size: 1rem;
}

p {
  font-size: 1.7rem;
  /* margin-bottom: 2.5vh; */
  line-height: 2.8rem;
}

a {
  text-decoration: none;
  color: black
}

section {
  min-height: 100vh;
  /* padding: calc(var(--nav-height) + 2%) 10% 0; */
  padding: calc(var(--nav-height) + 2%) 8% 0;
}



html[data-theme='light'] {
  --color-light: rgb(245, 255, 245);
  --color-primary: rgb(1, 208, 94);
  --color-primary-offset: rgb(217, 226, 82);
  /* --color-primary-offset: rgb(58, 170, 155); */
  /* --color-primary: rgb(81, 215, 168); */
  --color-secondary: rgb(58, 170, 155);
  --color-grey: rgb(115, 115, 115);
}

/* @media (max-width: 480px) {

  h1,
  h4 {
    text-align: center;
  }
} */


/*light blue svg for light page - #75c7fb  hsl(203,94%,72%)rgb(117,199,251) */
/*primary green-  #51d7a8  hsl(159,63%,58%) rgb(81,215,168) */

/*secondary green - #3aaa9b  hsl(172,49%,45%) rgb(58,170,155) */
/*grey text #737373  hsl(0,0%,45%)rgb(115,115,115) */

/*dark blue svg and bullets for darkpage-  #0048cd  hsl(219,100%,40%)rgb(0,72,205) */