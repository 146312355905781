#home {
    /* background-color: var(--color-light); */
    display: flex;
    align-items: center;
}

.home-container {
    /* background-color: red; */
    display: flex;
    align-items: center;
    /* margin-left: 8%; */
    height: 100%;
}

.home-main-content h1 {
    color: rgb(1, 208, 94);
}

.home-main-content h4 {
    color: var(--color-grey);
    margin: 3px 0 0 1px;
}

.home-container {
    flex: 1;
}

.home-img-container {
    flex: 1;
    margin-left: 2%;
    /* background-color: aqua; */
    width: 100%;
}


.home-brand {
    display: flex;
}

.home-brand-img {
    width: clamp(3rem, 12vw, 6.5rem);
    margin-right: 2%;
}

.home-img {
    width: 100%;
    margin-left: 5%;
    filter: drop-shadow(0 0 25px #333);
}

.sm-book-img {
    position: absolute;
    width: 80px;
    top: 20vh;
    left: 30vw;
    animation: float var(--medium) infinite forwards;
}



@media (max-width: 780px) {

    .home-container {
        display: block;
    }

    .home-img-container {
        display: none;
    }
}



@media (min-width: 780px) {


    .home-img-container {
        min-width: 40vh;
    }
}