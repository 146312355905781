#outsource,
#why-us {
    min-height: fit-content;
}

.outsource-container {
    padding-block: 5vh;
}

.outsource-content {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    margin-bottom: 2.5vh;
}

/* .bullet-img {
    max-width: 40px;
    height: 15px;
    margin-right: 1.3vw;
} */