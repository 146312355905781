/* section {
    min-height: unset;
} */

#contact {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    min-height: fit-content;
}


section>* {
    flex: 1;

}

.image-wrapper {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
}

.image-wrapper>img {
    width: 60vh;
    height: 80%;
    filter: drop-shadow(0 0 25px #333);
    animation: float var(--slow) infinite forwards;
    /* padding-inline: 10%;
    color: var(--color-bg);
    text-shadow: 0 0 0.3rem var(--color-primary); */

}


.mail-img {
    width: 100%;
    filter: drop-shadow(0 0 0.75rem var(--color-secondary));
    max-width: 40vh;
}

.contact {
    height: 100%;
    padding-top: 5%;
}

.contact>h1 {
    margin-bottom: 3%;
}

form {
    display: grid;
    grid-row-gap: 4vh;
    min-height: 60vh;
    padding-block: 5vh;
    margin-left: 5%;
}

input:not([type="checkbox"]),
textarea {
    width: 80%;
    min-height: 40px;
    /* min-height: 3rem; */
    border-radius: 3rem;
    padding-left: 2.5%;
    background-color: var(--color-bg);
    outline: 0.3rem solid var(--color-secondary);
    font-size: 1.5rem;
    font-family: inherit;
    border: none;
    opacity: 0.8;
}

textarea {
    border-radius: 1rem;
    resize: none;
    padding-top: .8rem;
    border: none;
}

input:focus,
textarea:focus {
    outline: 0.3rem solid var(--color-primary);
}

.sm-msg-img {
    position: absolute;
    right: 5vw;
    top: 15vh;
    width: 100px;
}

div.success,
div.failure {
    display: inline;
    margin-left: 30px;
    font-size: 1.6rem;
    text-decoration: solid;
    width: max-content;
}

form>div {
    display: flex;
    align-items: center;
    color: wheat;
}

div.success {
    background-color: green;
}

div.failure {
    background-color: red;
}

@media (max-width: 780px) {
    .contact {
        display: block;
    }

    .image-wrapper {
        display: none;
    }
}