button {
    cursor: pointer;
    border: none;
    font-size: 1.5rem;
    /* border: none; */
}

.button-wrapper {
    /* background-color: var(--color-primary); */
    background: linear-gradient(to bottom right, var(--color-primary-offset), var(--color-primary));
    width: max-content;
    border-radius: 22em;
    padding: 0.3rem .3rem;
    margin: 1em 0;
    box-shadow: 0px 0px 6px var(--color-primary-offset);
    /* transition-duration: var(--slow); */
    height: fit-content;
    /* box-shadow: -2px 0px 7px var(--color-bg-offset), 2px 2px 4px var(--color-bg-offset); */
}



.button-wrapper>button {
    /* font-size: 1.5rem; */
    padding: .7rem 1.5rem;
    min-width: 10rem;
    /* color: var(--color-button-text); */
    color: var(--color-light-text);
    border-radius: 22em;
    letter-spacing: 0.1em;
    font-weight: 600;
    transition-duration: var(--fast);
    background-color: transparent;

    /* background-color: var(--color-bg-offset); */
}


.button-wrapper>button:hover {
    /* background-color: var(--color-bg);    */
    background-color: white;
    color: var(--color-primary);
}

/* .button-wrapper:active {
    transform: scale(0.9);
    transition-duration: var(--slow);
    transition-delay: var(--fast);
} */


.bullet-img {
    /* max-width: 40px; */
    width: clamp(25px, 2.5vw, 40px);
    height: clamp(9px, 2.5vh, 15px);
    /* height: 15px; */
    /* margin-right: 1.4vw; */
    margin-right: max(1.4vw, 10px);
}


.animated-button {
    /* display: none; */
    animation: pop var(--slow) ease-out;
    display: inline;
    /* animation: pop 1.5s cubic-bezier(0.11, 0.93, 0.84, 1.38); */
}

section:nth-child(2n) {
    background-color: var(--color-light);
}

span[highlight='primary'] {
    color: var(--color-primary);
    text-decoration: underline;
}



@keyframes pop {
    0% {
        opacity: 0;
        display: none;
        transform: scale(0);
    }

    30% {
        opacity: 0;
        display: none;
        transform: scale(0);
    }

    60% {
        opacity: 1;
        display: block;
        transform: scale(1.04);
    }

    75% {
        transform: scale(0.8);
    }

    85% {
        transform: scale(1.04);
    }

    100% {
        transform: scale(1);
    }
}

@keyframes float {
    0% {
        transform: translateY(-1%);
    }

    50% {
        transform: translateY(1%);
    }

    100% {
        transform: translateY(-1%);

    }
}