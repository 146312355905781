header {
    /* min-height: 50vh; */
    position: fixed;
    /* max-height: 8vh; */
    /* text-align: center; */
    width: 100%;
    z-index: 99;
    /* padding-block: 5px; */
    color: var(--color-primary);
    background-color: white;
    box-shadow: 0 0.7px 10px black;
}

nav {
    position: absolute;
    width: 100%;
    background-color: white;
    z-index: 99;
    /* display: none; */
    /* transition-duration: var(--fast); */
    transform: scale(1, 0);
    /* transition-property: transform; */
    transform-origin: top;
    transition: transform var(--fast);
    /* transition-delay: 3s; */
    /* height: var(--nav-height); */
}

.item {
    text-align: center;
    font-size: 1.7rem;
    max-width: 50%;
    margin: 5% auto;
}

.item>a:hover {
    color: var(--color-primary);
}

.nav-items {
    display: flex;
    flex-direction: column;
    /* justify-content: flex-end; */
    /* height: 100%; */
    /* margin-right: 5vw; */
}




/* 
.nav-items>* {
    font-size: 1.3rem;
    margin-right: 3rem;
    margin-block: auto;
    position: relative;
    max-width: fit-content;
    text-decoration: none;
    box-sizing: content-box;

    font-size: 1.5rem;
    color: black;
} */

header .header-logo {
    /* margin: 1% auto 1% 5vw; */
    margin-left: 5vw;
    margin-right: auto;
    margin-block: 1rem;
    display: flex;
    /* text-align: left; */
}

.logo {
    width: 30px;
    margin-right: 2%;
}

.nav-toggle {
    display: none;
}

nav a {
    opacity: 0;
    transition: opacity var(--fast);
}

.nav-toggle:checked~nav {
    /* display: block; */
    transform: scale(1, 1);
    /* transition-delay: var(--fast); */
}

.nav-toggle:checked~nav a {
    opacity: 1;
}

.nav-toggle-icon {
    position: absolute;
    /* width: var(--nav-height);
    height: var(--nav-height); */
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 10vw;
    z-index: 999;
    top: 0;
    right: 0;
    /* font-size: calc(var(--nav-height) - 5%); */
}



/* nav:has(> .nav-items:has(> .item:has(> a))) {
    transform: scale(1, 0);
} */

/* .item a:active{

} */

.nav-toggle-icon i {
    font-size: large;
}

.nav-items>*:hover {
    color: var(--color-primary);
}

/* .nav-items>.active { */


/* .nav-items>*.active {
    box-shadow: 0 0.2rem 0 var(--color-secondary) inset;
} */

/* @media (max-width:800px) {


    .nav-items {
        display: block;
    }
} */

@media (min-width:800px) {
    .nav-toggle-icon {
        display: none;
    }

    header {
        display: flex;

    }

    nav {
        all: unset;
        display: flex;
        justify-content: center;
    }

    nav a {
        opacity: 1;
        font-size: 1.3rem;
        margin-right: 3rem;
        /* margin-block: auto; */
        position: relative;
        max-width: fit-content;
        text-decoration: none;
        box-sizing: content-box;

        font-size: 1.5rem;
        color: black;
    }

    nav .item {
        margin: 0;
        margin-block: auto;
    }

    nav .nav-items {
        flex-direction: row;
    }

    .item>.active {

        color: var(--color-primary);
        /* color: rgb(1, 208, 94); */
    }


    .item>*.active::before {
        content: "";
        position: absolute;
        display: block;
        width: 100%;
        height: 0.5rem;
        bottom: -1rem;
        background-color: var(--color-primary);
    }
}