#about {
    min-height: fit-content;
    padding-inline: 10%;
}

.about-content {
    margin-block: 5vh;
}

.about-content-points {
    display: flex;
    align-items: center;
    margin-bottom: 2.5vh;

}


/* .bullet-img {
    max-width: 40px;
    height: 15px;
    margin-right: 1.3vw;
} */

/* .about-content::before {
    font-family: "Font Awesome 5 Brands";
    content: "\f099";
} */